import httpClient from "../httpClient";
const baseSlug = "/orders";

class httpService {
  createOrder(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  updateOrder(id, data) {
    return httpClient.put(`${baseSlug}/status/${id}`, data);
  }

  getAllOrders() {
    return httpClient.get(`${baseSlug}`);
  }

  getUserOrders() {
    return httpClient.get(`${baseSlug}/ofUser`);
  }

  getOrderById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  cancelOrder(id, reason) {
    return httpClient.put(`${baseSlug}/cancel/${id}`, reason);
  }

  returnlOrder(id, data, config) {
    return httpClient.put(`${baseSlug}/return/${id}`, data, config);
  }

  bulkUpdateStatus(data) {
    return httpClient.put(`${baseSlug}/status/bulk`, data);
  }

  getOrderByDates(data) {
    return httpClient.post(`${baseSlug}/byDates`, data);
  }

  makePayment(id, data) {
    return httpClient.put(`${baseSlug}/payment/${id}`, data);
  }
}

export default new httpService();

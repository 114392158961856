import React, { useEffect, useState } from "react";
import {
  Table,
  Badge,
  Container,
  Row,
  Col,
  Pagination,
  IconButton,
  Tag,
} from "rsuite";
import "./myOrders.css";
import OrdersService from "../../../services/orders.service";
import moment from "moment/moment";
import { Link, useLocation } from "react-router-dom";
import { TiThSmallOutline } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../../stores/appSlice";
import { useCart } from "../../../utilities/cart";
import ScrollToTop from "../../../utilities/ScrollToTop";
import { formatDate, formatDateTime } from "../../../utilities/formatDate";
import { trackPromise } from "react-promise-tracker";
import {
  isSmallScreen,
  useSmallScreen,
} from "../../../utilities/useWindowSize";
import { FaThList } from "react-icons/fa";
import { formatAmount } from "../../../utilities/formatAmount";

const { Column, HeaderCell, Cell } = Table;

const DateCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>{formatDateTime(rowData[dataKey])}</Cell>
);

const MyOrdersList = ({ pageTitle }) => {
  const { getCart } = useCart();
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const location = useLocation();

  const getOrders = async () => {
    const resp = await trackPromise(OrdersService.getUserOrders());
    setOrders(resp.data.data);
  };

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    getOrders();
    getCart();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeLimit = (dataKey) => {
    setLimit(dataKey);
  };

  const startIndex = (currentPage - 1) * limit;
  const paginatedOrders = orders.slice(startIndex, startIndex + limit);

  const isSmallScreen = useSmallScreen(768);

  return (
    <Container className="orders-bg">
      <ScrollToTop />
      <Row>
        <Col xs={20} className="orders-container">
          <div style={{ padding: "1.25rem" }}>
            <h4>Orders</h4>
            <Table
              wordWrap="break-word"
              data={paginatedOrders}
              autoHeight={true}
            >
              <Column width={190}>
                <HeaderCell>OrderId</HeaderCell>
                <Cell dataKey="_id">
                  {(rowData) => (
                    <Link
                      className="prod-color prod-hover fw-10"
                      to={`/my-orders/${rowData._id}`}
                    >
                      {rowData.orderId}
                    </Link>
                  )}
                </Cell>
              </Column>

              <Column width={80}>
                <HeaderCell>Count</HeaderCell>
                <Cell>{(rowData) => rowData?.items?.length}</Cell>
              </Column>

              <Column width={200}>
                <HeaderCell>Date / Time</HeaderCell>
                <DateCell dataKey="createdAt" />
              </Column>

              <Column width={170}>
                <HeaderCell>Payment Mode</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div className="upper">
                      {rowData?.paymentMode === "cod"
                        ? "Cash on Delivery"
                        : rowData?.paymentMode}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column align="center" width={100}>
                <HeaderCell>Status</HeaderCell>
                <Cell>
                  {(rowData) => {
                    const statusColor = (status) => {
                      switch (status) {
                        case "processing":
                          return "yellow";
                        case "placed":
                          return "blue";
                        case "returned":
                          return "orange";
                        case "delivered":
                          return "green";
                        case "cancelled":
                          return "red";
                        case "dispatched":
                          return "violet";
                        default:
                          return "gray";
                      }
                    };
                    return (
                      <Tag
                        color={statusColor(rowData.status)}
                        style={{
                          borderRadius: "25px",
                          textTransform: "capitalize",
                        }}
                      >
                        {rowData.status}
                      </Tag>
                    );
                  }}
                </Cell>
              </Column>

              <Column width={150}>
                <HeaderCell>City</HeaderCell>
                <Cell>{(rowData) => rowData?.deliveryAddress?.city}</Cell>
              </Column>

              <Column width={120}>
                <HeaderCell>Delivery Date</HeaderCell>
                <Cell>
                  {(rowData) =>
                    rowData?.deliveryDate
                      ? formatDate(rowData?.deliveryDate)
                      : " - "
                  }
                </Cell>
              </Column>

              <Column width={100}>
                <HeaderCell>Total (₹)</HeaderCell>
                <Cell dataKey="total">
                  {(rowData) => formatAmount(rowData?.total)}
                </Cell>
              </Column>
            </Table>
            <div className="pagination">
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={isSmallScreen ? 2 : 5}
                size={isSmallScreen ? "xs" : "md"}
                layout={[
                  "total",
                  "-",
                  `${!isSmallScreen ? "limit" : ""}`,
                  `${!isSmallScreen ? "|" : ""}`,
                  "pager",
                  `${!isSmallScreen ? "|" : ""}`,
                  `${!isSmallScreen ? "skip" : ""}`,
                ]}
                total={orders.length}
                limitOptions={[5, 10, 30, 50]}
                limit={limit}
                activePage={currentPage}
                onChangePage={handlePageChange}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MyOrdersList;

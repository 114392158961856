import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import OrdersService from "../../services/orders.service";
import { trackPromise } from "react-promise-tracker";

import {
  Button,
  Col,
  Container,
  FlexboxGrid,
  List,
  Panel,
  Row,
  Steps,
  Table,
  Text,
} from "rsuite";
import "./order.css";
import { formatDate, formatDateTime } from "../../utilities/formatDate";
import StatusTimeline from "./StatusTimeline";
import EnvConfig from "../../envConfig";
import ScrollToTop from "../../utilities/ScrollToTop";
import { Link } from "react-router-dom";
import { generateInvoicePDF } from "../../utilities/generateInvoice";
import invoiceService from "../../services/invoice.service";
import Column from "rsuite/esm/Table/TableColumn";
import { Cell, HeaderCell } from "rsuite-table";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { formatAmount } from "../../utilities/formatAmount";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import { toast } from "react-toastify";

const ImageCell = ({ rowData, ...props }) => {
  return (
    <Cell {...props} className="image-cell gdavsx">
      <Link to={`/products/${rowData?.product?._id}`}>
        <img
          src={`${EnvConfig?.MediaBase}/${rowData?.product?.assetsDir}/${rowData?.product?.prodImages?.[0]}`}
          alt="Product"
        />
      </Link>
    </Cell>
  );
};

const OrderDetails = ({ pageTitle }) => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const authState = useSelector((state) => state.authState);
  const adminAddress = authState?.user?.addresses?.[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  const fetchOrder = async () => {
    try {
      const resp = await trackPromise(OrdersService.getOrderById(id));
      setOrder(resp.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Failed to fetch Order", error);
    }
  };

  const getInvoice = async () => {
    try {
      const resp = await trackPromise(invoiceService.byOrder(id));
      setInvoice(resp.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error creating invoice", error);
    }
  };

  useEffect(() => {
    fetchOrder();
    getInvoice();
  }, [id]);

  if (!order) return <div>Loading...</div>;

  const hide =
    order?.status === "returned" ||
    order?.status === "cancelled" ||
    order?.status === "delivered";

  const totalPrice = order.items.reduce((acc, item) => acc + item.price, 0);
  const totalTax = order.items.reduce((acc, item) => acc + item.taxAmt, 0);
  const totalDiscount = order.items.reduce(
    (acc, item) => acc + item.discount,
    0
  );
  const grandTotal = order.items.reduce((acc, item) => acc + item.total, 0);

  const footerData = {
    totalPrice,
    totalTax,
    totalDiscount,
    grandTotal,
  };

  return (
    <Container className="order-details-container">
      <ScrollToTop />
      <Row className="order-summary-address">
        <Col xs={24} lg={16} className="dets-i">
          <div className="flex-id">
            <h3>
              <span className="my-order-det-head">Order Id # </span>{" "}
              {order?.orderId}
            </h3>

            {invoice !== null ? (
              <Button
                appearance="primary"
                onClick={() => generateInvoicePDF(invoice)}
              >
                Download Invoice
              </Button>
            ) : (
              ""
            )}
            <Button
              onClick={() => navigate(-1)}
              appearance="ghost"
              color="blue"
            >
              <ArowBackIcon />
            </Button>
          </div>
          {order.status === "cancelled" ? (
            <div className="order-summary-ad3d mr-10">
              <h5 className="error-message">
                <strong>
                  Order {order?.status} by {order?.canceledBy}
                </strong>
              </h5>

              {order?.cancelReason ? (
                <div className="flex12">
                  Cancel Reason{" "}
                  <strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: order?.cancelReason,
                      }}
                    ></div>
                  </strong>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className="order-summary-add mr-10">
            <Row style={{ width: "100%" }}>
              <Col xs={24} md={12}>
                <div className="order-summary">
                  <h5 className="order-det-head">Order Summary</h5>
                  <Row className="order-summary-row">
                    <Col xs={20} className="order-det-body">
                      <p>Subtotal (₹)</p>
                    </Col>
                    <Col xs={4} className="text-right">
                      <p>
                        <strong>{formatAmount(order?.amount)}</strong>
                      </p>
                    </Col>
                  </Row>

                  <Row className="order-summary-row">
                    <Col xs={20} className="order-det-body">
                      <p>Tax (₹)</p>
                    </Col>
                    <Col xs={4} className="text-right">
                      <p>
                        <strong>{formatAmount(order?.tax)}</strong>
                      </p>
                    </Col>
                  </Row>

                  <Row className="order-summary-row">
                    <Col xs={20} className="order-det-body">
                      <p>Charge (₹)</p>
                    </Col>
                    <Col xs={4} className="text-right">
                      <p>
                        <strong>{formatAmount(order?.charge)}</strong>
                      </p>
                    </Col>
                  </Row>

                  <Row className="order-summary-row">
                    <Col xs={20} className="order-det-body">
                      <p>Discount (₹)</p>
                    </Col>
                    <Col xs={4} className="text-right">
                      <p>
                        <strong>-{formatAmount(order?.discount)}</strong>
                      </p>
                    </Col>
                  </Row>

                  <Row className="order-summary-row total-row">
                    <Col xs={20} className="order-det-body">
                      <p>Total (₹)</p>
                    </Col>
                    <Col xs={4} className="text-right">
                      <p>
                        <strong>{formatAmount(order?.total)}</strong>
                      </p>
                    </Col>
                  </Row>

                  <Row className="order-summary-row">
                    <Col xs={20} className="order-det-body">
                      <p>Order Date</p>
                    </Col>
                    <Col xs={4} className="text-right">
                      <p>
                        <strong>{formatDate(order?.createdAt)}</strong>
                      </p>
                    </Col>
                  </Row>

                  <Row className="order-summary-row">
                    <Col xs={20} className="order-det-body">
                      <p>Delivery Date</p>
                    </Col>
                    <Col xs={4} className="text-right">
                      <p>
                        <strong>
                          {order?.deliveryDate
                            ? formatDate(order?.deliveryDate)
                            : "-"}
                        </strong>
                      </p>
                    </Col>
                  </Row>

                  <Row className="order-summary-row">
                    <Col xs={20} className="order-det-body">
                      <p>Status</p>
                    </Col>
                    <Col xs={4} className="text-right">
                      <p>
                        <strong className="order-status">
                          {order?.status}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  <Row className="order-summary-row">
                    <Col xs={12} className="order-det-body">
                      <p>Payment Mode</p>
                    </Col>
                    <Col xs={12} className="text-right">
                      <p>
                        <strong className="order-status">
                        {order?.paymentMode === "cod" ? "Cash on Delivery" : order?.paymentMode}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  {order?.paymentMode === "upi" && (
                    <Row className="order-summary-row">
                      <Col xs={20} className="order-det-body">
                        <p>Transaction Id</p>
                      </Col>
                      <Col xs={4} className="text-right">
                        <p>
                          <strong className="order-status">
                            {order?.transactionId}
                          </strong>
                        </p>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>

              <Col xs={24} md={12}>
                <div className="order-address">
                  <h5 className="order-det-head">Address</h5>
                  <p>
                    <strong>{`${order?.deliveryAddress?.city}, ${order?.deliveryAddress?.state} - ${order?.deliveryAddress?.pincode}`}</strong>
                  </p>
                  <p>
                    <strong>{order?.deliveryAddress?.lane}</strong>
                  </p>
                  <p>
                    <strong>{order?.deliveryAddress?.landmark}</strong>
                  </p>

                  <h5 className="order-det-head" style={{ marginTop: "15px" }}>
                    User Info
                  </h5>
                  <p>
                    <strong>{order?.deliveryAddress?.phone}</strong>
                  </p>
                  <p>
                    <strong>{`${order?.user?.firstName} ${order?.user?.lastName}`}</strong>
                  </p>
                  <p>
                    <strong>{order?.user?.email}</strong>
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          {order?.userNote && (
            <FlexboxGrid className="order-summary-add mr-10">
              <FlexboxGrid.Item colspan={24}>
                <div>
                  User Note
                  <div>
                    <strong>{order?.userNote}</strong>
                  </div>
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          )}

          <div className="products-ybale mr-10">
            <h5>Products</h5>
            <Table data={order?.items} autoHeight>
              <Column width={100} align="center">
                <HeaderCell>Image</HeaderCell>
                <ImageCell />
              </Column>
              <Column width={200}>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="product.name">
                  {(rowData) => (
                    <Link to={`/products/${rowData?.product?._id}`}>
                      {rowData.product.name}
                    </Link>
                  )}
                </Cell>
              </Column>
              <Column width={100}>
                <HeaderCell>Price</HeaderCell>
                <Cell>{(rowData) => formatAmount(rowData?.price)}</Cell>
              </Column>
              <Column width={100}>
                <HeaderCell>Quantity</HeaderCell>
                <Cell>{(rowData) => rowData?.quantity}</Cell>
              </Column>
              <Column width={100}>
                <HeaderCell>Tax</HeaderCell>
                <Cell>{(rowData) => formatAmount(rowData?.taxAmt)}</Cell>
              </Column>
              <Column width={100}>
                <HeaderCell>Discount</HeaderCell>
                <Cell>{(rowData) => formatAmount(rowData?.discount)}</Cell>
              </Column>
              <Column width={90} fixed="right">
                <HeaderCell>Total</HeaderCell>
                <Cell>{(rowData) => formatAmount(rowData?.total)}</Cell>
              </Column>
            </Table>
            {/* <div className="grabdd">
              <div className="order-det-body">
                <h5 className="text-right">Grand Total</h5>
              </div>
              <div className="order-det-body">
                <h5 className="text-right">
                  <strong></strong>
                  {order?.total}
                </h5>
              </div>
            </div> */}
          </div>
          {order?.status === "returned" && (
            <div className="order-return">
              <h5>Order Returned</h5>
              <div className="returned-order">
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={4}>
                    <img
                      className="order-prod-image"
                      src={`${EnvConfig.MediaBase}/${order?.assetsDir}/${order?.returnImages?.[0]}`}
                      alt={order?.name}
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={20}>
                    <FlexboxGrid justify="space-between">
                      <FlexboxGrid.Item colspan={12}>
                        <div
                          className="retuurm"
                          dangerouslySetInnerHTML={{
                            __html: order?.returnReason,
                          }}
                        ></div>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>
            </div>
          )}
        </Col>
        <Col xs={24} lg={8} className="order-details-right">
          <h5>Order Status</h5>
          <StatusTimeline order={order} hide={hide} />
        </Col>
      </Row>
    </Container>
  );
};

export default OrderDetails;
